import React from 'react';
import ModalVideo from 'react-modal-video';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ScrollAnimation from 'react-animate-on-scroll';

class SideBySideVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpenIndex: null,
    };
  }

  render() {
    const { hasFade, backgroundColorOption, cardOption } = this.props.fields;
    const bgc = backgroundColorOption[0].fields.color.value;
    const contentBlock = (
      <div
        className="side-by-side-video"
        style={{
          backgroundColor: bgc,
        }}
      >
        <div className="l-padding">
          <div className="side-video-cards">
            <div className="content-container__grouper">
              <div className="sl  is-55-split">
                <div className="sl-list has-2-items">
                  {cardOption.map((card, index) => {
                    return (
                      <React.Fragment key={card.fields.id}>
                        {index < 2 ? (
                          <div className="sl-item">
                            <div className="card">
                              <div className="product-media-banner">
                                {card.fields.videoId &&
                                card.fields.videoId.value ? (
                                  <React.Fragment>
                                    <button
                                      className="video-modal-cta"
                                      type="button"
                                      onClick={() =>
                                        this.setState({
                                          modalOpenIndex: index,
                                        })
                                      }
                                      aria-label={
                                        card.fields.ariaLabel
                                          ? card.fields.ariaLabel.value
                                          : 'Watch this video'
                                      }
                                    >
                                      <div className="video-banner">
                                        <Image field={card.fields.image} />
                                      </div>
                                    </button>
                                    <ModalVideo
                                      channel="youtube"
                                      isOpen={
                                        this.state.modalOpenIndex === index
                                      }
                                      onClose={() =>
                                        this.setState({
                                          modalOpenIndex: null,
                                        })
                                      }
                                      videoId={card.fields.videoId.value}
                                      youtube={{
                                        autoplay: 1,
                                        enablejsapi: 1,
                                        rel: 0,
                                      }}
                                    />
                                  </React.Fragment>
                                ) : (
                                  <div className="video-banner">
                                    <Image field={card.fields.image} />
                                  </div>
                                )}
                              </div>
                              <div className="product-media-title">
                                <Text field={card.fields.title} tag="h2" />
                                <RichText
                                  field={card.fields.description}
                                  className="content"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {contentBlock}
      </ScrollAnimation>
    ) : (
      contentBlock
    );
  }
}

SideBySideVideo.propTypes = PropTypes.SitecoreItem.inject({
  ...DefaultProps.ContentBlock,
  hasFade: PropTypes.Checkbox,
  backgroundColorOption: PropTypes.Multilist.inject({
    color: PropTypes.SingleLineText,
  }),
  cardOption: PropTypes.Multilist.inject({
    title: PropTypes.SingleLineText,
    description: PropTypes.RichText,
    image: PropTypes.Image,
    videoId: PropTypes.SingleLineText,
  }),
});

SideBySideVideo.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ContentBlock,
  hasFade: DefaultProps.Checkbox,
  backgroundColorOption: DefaultProps.Multilist,
  processOption: DefaultProps.Multilist,
});

export default SideBySideVideo;
